import React from "react";

import { useEffect, useState } from "react";
import axios from "axios";


import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";



import QuotaDialog from "./QuotatDialog";
const baseUrl = "https://nmpi-v3.hbpneuromorphic.eu/";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function fetchQuotas(projectId, auth) {
  const config = {
    headers: {
      Authorization: "Bearer " + auth.token,
    },
  };
  const quotasListUrl = `${baseUrl}projects/`+projectId+`/quotas/`;
  return axios.get(quotasListUrl, config);
}

function saveQuota(quota, collabId, auth, submit) {
  const config = {
    headers: {
      Authorization: "Bearer " + auth.token,
    },
  };
  
  if (quota.resource_uri) {
    // previously saved

    const quotaUpdateUrl = `${baseUrl}${quota.resource_uri}`;
    console.log(`Not Updated yet ${quotaUpdateUrl}`);
    axios.put(quotaUpdateUrl, quota, config) ;
    console.log(`Updated ${quotaUpdateUrl}`);
    console.log(quota);
  } else {
   
    quota.project = collabId;
   
   // quota.platform="SpiNNaker";
    const quotaCreateUrl = `${baseUrl}projects/${collabId}/quotas/`;
    axios.post(quotaCreateUrl, quota, config);
    console.log("Created new quota");
    console.log(quota);
  }
}




function Quota(props) {
    const [quotas, setQuotas] = useState([{}]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedQuotaIndex, setSelectedQuotaIndex] = useState(0);
    const [currentlySelected, setCurrentlySelected] = useState(null);
    const classes = useStyles();

useEffect(() => {
  fetchQuotas(props.collab, props.auth)
  .then((response) => {
    setQuotas(response.data);
    console.log(response.data);
  })
  .catch((err) => {
    console.log(err);
  });
}, [selectedQuotaIndex]);



const handleClose = () => {
  setDialogOpen(false);
};

const handleChange = (updatedQuota, submit) => {
  let updatedQuotas = [...quotas];
  let localIndex = selectedQuotaIndex;
  if (localIndex < 0) {
    localIndex = quotas.length;
    setSelectedQuotaIndex(localIndex);
    updatedQuotas.push(updatedQuota);
  }
  
    updatedQuotas[localIndex] = updatedQuota;
    console.log('updatedquotas');
    console.log(updatedQuotas);

    setQuotas(updatedQuotas);
    saveQuota(updatedQuota, props.collab, props.auth, submit);
  };

const handleCreate = () => {
  setSelectedQuotaIndex(-1);
  setCurrentlySelected(null);
  setDialogOpen(true);
};

  console.log('quotas');
        console.log(quotas);
        
        console.log('quotas');
  

    return (
     
      <div className={classes.root}>
    
    
       
    
            
            <TableContainer component={Paper}>    
        <Table >
            <TableHead>
              <TableRow>
              
                <TableCell>Platform</TableCell>
                <TableCell>Limit</TableCell>
                <TableCell>Units</TableCell>
               
                <TableCell>Usage</TableCell>
              </TableRow>
            </TableHead>
           
               <TableBody> 
             
                
              {quotas.map((rr, index) => (
    
    <TableRow key={rr.project}>
                <TableCell component="th" scope="row">
                  {rr.platform}
                </TableCell>
     
             
      <TableCell>{rr.limit}</TableCell>
  
      <TableCell>{rr.units}</TableCell>
      <TableCell>{rr.usage}</TableCell>
    </TableRow>  )) } 
            </TableBody> 
            
          </Table> </TableContainer> 
          <Button
        onClick={handleCreate}
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<AddIcon />}
          >
        Add Quotas <QuotaDialog
        projectId={props.collab}
        quota={currentlySelected}
        open={dialogOpen}
        onClose={handleClose}
        onChange={handleChange}
      />
      </Button>
      <QuotaDialog
        projectId={props.collab}
        quota={currentlySelected}
        open={dialogOpen}
        onClose={handleClose}
        onChange={handleChange}
      />
      
    </div>
           
           );

}
export default Quota
