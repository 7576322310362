import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { makeStyles } from '@material-ui/core';

import './App.css';


import ResourceRequestList from './Quotas/ResourceRequestList.js';


const useStyles = makeStyles((theme) => ({
  plainLink: {
    color: 'white'
  }
}));


function App(props) {
  console.log("ùùùùùùùùùùùùùùùùùùùùùùùùùùù");
  console.log(props.auth.tokenParsed);
  console.log("ùùùùùùùùùùùùùùùùùùùùùùùùùùùùùù");
  const classes = useStyles();

  const [currentCollab, setCurrentCollab] = React.useState(null);

  React.useEffect(() => {
    let params = (new URL(document.location)).searchParams;
    let requestedCollabId = params.get('clb-collab-id');
    console.log("kkkkkkkkkkkkkclb-collab-idkkkkkkkkkkkkkkkkkkkkk");
    console.log(requestedCollabId);
    console.log("kkkkkkkkkkkkkkkclb-collab-idkkkkkkkkkkkkkkkkkkk");
    if (requestedCollabId) {
      setCurrentCollab(requestedCollabId);
    }
    console.log("kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk");
    console.log(`Requested ${requestedCollabId}`);
    console.log("kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk");
  }, [currentCollab]);

  return (
    <Router>
   <header className="navbar navbar-expand navbar-dark fixed-top bg-dark">
      <div className="navbar-brand"><Link to={`/?clb-collab-id=${currentCollab}`} className={classes.plainLink}>
        EBRAINS Neuromorphic Platform Admin
        </Link></div>
        <div className="ml-auto order-lg-last">
        <ul className="navbar-nav flex-row">
          <li className="nav-item pr-3 pr-lg-0">
            <div className="nav-link"><Link to={`/?clb-collab-id=${currentCollab}`} className={classes.plainLink}>Others</Link></div>
          </li>
          
        </ul>
      </div>
    </header>
    <Switch>
      <Route exact path="/">
          <ResourceRequestList auth={props.auth} collab={currentCollab} setCollab={setCurrentCollab} />
      </Route>
      
      
    </Switch>
  </Router>
  );
}

export default App;
